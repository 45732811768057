export const CODE_ENUM = {
  SUCCESS: 0,
  // 未登录
  AUTHORIZATION_NOT_LOGIN: 401,
  // 权限
  AUTHORIZATION_NOT: 403,
  AUTHORIZATION_NOT_AUTH: 90000,
}

// 服务
export const SUFFIX = {
  TIANSHU: '/api-tianshu',
  USER: '/api-user',
}

// 报表类型
export const CHARTS_TYPE_ENUM = {
  BAR: 'BAR',
  LINE: 'LINE',
  PIE: 'PIE',
}

// 报表类型
export const CHARTS_TIME_ENUM = {
  HOURE_48_TYPE: '48H',
  DAY_15_TYPE: '15D',
  MONTH_12_TYPE: '12M',
  YEAR_5_TYPE: '5Y',
}

// 告警类别
export const WARNING_TYPE_ENUM = {
  AOI: 'aoi', // 电子围栏告警
  SENSOR: 'sensor', //传感器阙值告警
  SENSORINCRE: 'sensorincre', //传感器增量告警
  EVENT: 'event', // 事件告警
}

// 告警状态
export const WARNING_STAUTS_ENUM = {
  START: 'start', // 电子围栏告警
  STOP: 'stop', //传感器阙值告警
}

// 地图类型
export const MAP_TYPE_ENUM = {
  STAR: 'STAR', // 卫星地图
  STAND: 'STAND', //标准地图
  TERRAIN: 'TERRAIN', //地形图
}

// 告警策略时间类型
export const TACTICS_TIME_TYPE_ENUM = {
  ALWAYS: 'always', // 一直生效
  CUSTOM: 'custom', // 自定义时间
}
// 设备状态
export const DEVICE_STATUS_ENUM = {
  NOACTIVATE: 'noactivate', // 未激活
  INACTIVATE: 'inactivate', // 已激活
  OFFLINE: 'offline', // 离线
  ONLINE: 'online', // 在线
}
// 数据配置- 数据上传方式
export const DEVICE_DATA_UPLOAD_ENUM = {
  PROPERTIES: 'properties', // 变量模版
  UNRESOLVED: 'unresolved', // 透传
}

// 数据配置- 设备定位方式
export const DEVICE_LOCATION_TYPE_ENUM = {
  AUTO: 'auto', // 自动定位
  HAND: 'hand', // 手工定位
}

// 告警推送类型
export const PUSH_ACTION_TYPE_ENUM = {
  ALARM_ACTION_ALWAYS: 'ALARM_ACTION_ALWAYS', //一直
  ALARM_ACTION_INTERVAL_HOUR: 'ALARM_ACTION_INTERVAL_HOUR', //间隔一小时
  ALARM_ACTION_INTERVAL_DAY: 'ALARM_ACTION_INTERVAL_DAY', //间隔一天
}

export const PRODUCT_TYPE = {
  SINGLE_GATEWAY: 'SINGLE_GATEWAY', //单模
  MULTI_GATEWAY: 'MULTI_GATEWAY', // 多模
  LORA: 'LORA', // LORA
}

// 运算符
export const OPERATOR_TYPE = {
  GREATER: 'GREATER', //大于
  LESS: 'LESS', // 小于
  EQUAL: 'EQUAL', // 等于
  GREATER_EQUAL: 'GREATER_EQUAL', // 大于等于
  LESS_EQUAL: 'LESS_EQUAL', // 小于等于
  GREATER_EQUAL_AND_LESS_EQUAL: 'GREATER_EQUAL_AND_LESS_EQUAL', // 大于等于且小于等于
}

// 电子围栏方向
export const LOCUS_DIRECTION_TYPE = {
  IN: 'IN', //进方向
  OUT: 'OUT', //出方向
  IN_AND_OUT: 'IN_AND_OUT', //进出方向
}

// 电子围栏形状
export const LOCUS_SHAPE_TYPE = {
  POLYGON: 'POLYGON', //多边形
  ROUND: 'ROUND', // 圆形
}

// 告警满足条件
export const ALARM_SATISFY_TYPE = {
  SATISFY_ALL: 'SATISFY_ALL', //满足全部
  SATISFY_ONE: 'SATISFY_ONE', // 满足其中之一
}

// 告警等级
export const ALARM_LEVEL_TYPE = {
  ALARM_MAIN: 'ALARM_MAIN', //主要
  ALARM_MINOR: 'ALARM_MINOR', //次要
  ALARM_URGENCY: 'ALARM_URGENCY', //紧急
  ALARM_WARN: 'ALARM_WARN', //警告
  ALARM_CLEAN: 'ALARM_CLEAN', //清除
}

// 告警规则模式
export const ALARM_MODE_TYPE = {
  SIMPLE: 'SIMPLE', // 简单
  DURING_TIME: 'DURING_TIME', // 持续时长
  DURING_NUM: 'DURING_NUM', // 持续次数
}

// 告警时间
export const ALARM_TIME_TYPE = {
  SECOND: 'SECOND', // 秒
  MINUTE: 'MINUTE', // 分
  HOUR: 'HOUR', // 时
  DAY: 'DAY', // 天
}

// 告警分类
export const ALARM_TYPE = {
  MSG_TITLE_SENSOR_ALARM: 'msgTitleSensorAlarm', //传感器告警
  MSG_TITLE_SENSOR_INCREALARM: 'msgTitleSensorIncreAlarm', //传感器增量告警
  MSGT_ITLE_AOI_ALARM: 'msgTitleAoiAlarm', //电子围栏告警
  DEVICE_ALARM: 'deviceAlarm', //设备告警
  EVENT_ALARM: 'eventAlarm', //事件告警
}

// 属性展示方式
export const DEVICE_VAR_TYPE = {
  DEFAULT: 'DEFAULT', //默认
  WATER: 'WATER', // 水流
}

// 文件类型
export const FILES_TYPE = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  OTHER: 'OTHER',
}

// 报表配置数据类型
export const REPORT_DATA_TYPE = {
  ORIGIN: 'ORIGIN', //原始数据
  AGGREGATION: 'AGGREGATION', //聚合数据
}

// 报表配置执行方式
export const REPORT_EXECUTE_METHOD_TYPE = {
  IMMEDIATELY: 'IMMEDIATELY', //立即执行
  CYCLE: 'CYCLE', //周期执行
}

// 报表配置执行周期
export const REPORT_EXECUTE_FREQUENCY_TYPE = {
  DAY: 'DAY', //每天
  WEEK: 'WEEK', //每周
  MONTH: 'MONTH', //每月
}

// 报表配置最近时间
export const REPORT_TIME_TYPE = {
  LAST_ONE_DAY: 'LAST_ONE_DAY', //最近一天
  LAST_ONE_WEEK: 'LAST_ONE_WEEK', //最近一周
  LAST_ONE_MONTH: 'LAST_ONE_MONTH', //最近一个月
  LAST_THREE_MONTH: 'LAST_THREE_MONTH', //最近三个月
  LAST_ONE_YEAR: 'LAST_ONE_YEAR', //最近一年
  CUSTOM: 'CUSTOM', // 自定义
}

// 报表类型
export const BOARD_TYPE = {
  TEXT: 'TEXT', //文本组件
  INSTRUMENT: 'INSTRUMENT', //仪表组件
  VIDEO: 'VIDEO', //视频组件
  LOCATION: 'LOCATION', //定位组件
  STATISTICS: 'STATISTICS', //统计组件
}

// 报表视频类型
export const BOARD_VIDEO_TYPE = {
  STREAMING: 'STREAMING', //媒体流
}

// 角色状态
export const STATUS_TYPE = {
  ROLE_START: 'ROLE_START', //启用
  ROLE_STOP: 'ROLE_STOP', //未启用
}

// 设备接入方式
export const CONNECT_TYPE = {
  '4G': '4G',
  LEO: 'LEO',
  GEO: 'GEO',
  LORA: 'Lora',
}

export const MAP_ICON = {
  DEFAULT: 'DEFAULT', //车
  CAR: 'CAR', //车
  SHIP: 'SHIP', //渔船
  COW: 'COW', //奶牛
  FIRE: 'FIRE', //火
  ANIMAL: 'ANIMAL', //动物
  PM25: 'PM25', //pm2.5
  PERSON: 'PERSON', //人流量
  SLOPE: 'SLOPE', //滑坡
}

// 数据类型
export const DATA_TYPE = {
  INT: 'int',
  DOUBLE: 'double',
  STRING: 'string',
  DATE: 'date',
  BOOL: 'bool',
  STRUCT: 'struct',
}

// 文件内容类型
export const FILE_CONTENT_TYPE = {
  RARE_ANIMAL: 'RARE_ANIMAL', //珍稀动物
  FIRE: 'FIRE', //火灾
  OTHER: 'OTHER', //其他
}

// 文件查询类型
export const FILE_TYPE = {
  TEXT: 'TEXT', //文本
  IMAGE: 'IMAGE', //图片
  VIDEO: 'VIDEO', //视频
  AUDIO: 'AUDIO', //音频
  OTHER: 'OTHER', //其他
}

// 事件告警类型
export const EVENT_ALARM_TYPE = {
  EVENT_ANIMAL: 'EVENT_ANIMAL', //监测到野生动物
  EVENT_FIRE: 'EVENT_FIRE', //监测到火警
  EVENT_OFFLINE: 'EVENT_OFFLINE', //离线告警
  EVENT_UNUPDATE: 'EVENT_UNUPDATE', //星历未更新
}
