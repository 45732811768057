import { resolveGet, resolvePost, get, post } from '@/request'
import { SUFFIX } from '@/common'

// 获取网关产品信息(除子设备)
export const queryProductGatewayList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/product/gatewayList/query',
    params,
  })
}
// 获取子设备产品信息
export const queryProductSubDeviceList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/product/subDeviceList/query',
    params,
  })
}
// 获取总线类型信息
export const queryProductBusType = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/product/busType/query',
    params,
  })
}

// 获取租户设备产品模版列表
export const queryDevicethingList = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/thing/manage/base/list/queryByOrg',
    params,
  })
}

// 根据产品模版id分页查询-设备列表
export const queryDevicethingDeviceList = async (params?: any) => {
  return resolvePost({
    suffix: SUFFIX.TIANSHU,
    url: '/device/manage/device/page/thingModelId',
    params,
  })
}

// 根据产品模版id获取物模型属性元数据
export const queryDevicethingAttrList = async (params?: any) => {
  return resolveGet({
    suffix: SUFFIX.TIANSHU,
    url: '/device/thing/manage/thingmodel/getProductThingModelMetadata',
    params,
  })
}
